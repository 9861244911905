import {GridHeaderCellProps} from '@progress/kendo-react-grid'
import { TAB_INDEX } from '../../../../../app/modules/jobs/constant/tabindex-default'

interface ButtonHeaderProps {
  title: any
  clickHandler: () => void
  isCanEdit?: boolean
  tabIndex?: number
  onKeyDown?: (e: any) => void
}

export const AddButtonHeader = (props: ButtonHeaderProps) => {

  // While focus is in add button, added keydown for tabbing to focus on grid items
  const onKeyDown = (event: any) => {
    if (event.keyCode === 9) {
      if (props.onKeyDown) {
        props.onKeyDown({
          dataIndex: 0,
          colIndex: 0
        })
      }
    }
  }

  return (
    <a className='k-link text-center' onClick={props.clickHandler}>
      {(props.isCanEdit === undefined || props.isCanEdit) && (
        <button type='button' className='btn btn-primary' style={{width: 'auto'}} tabIndex={props.tabIndex} onKeyDown={onKeyDown}>
          <span className='k-icon k-i-plus' />
          {/* <span>{props.title}</span> */}
        </button>
      )}
    </a>
  )
}
